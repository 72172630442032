import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { PUBLIC_SHOP_OFFER } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';
import { getFormattedDate } from 'Utils/date';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import TextBox from 'Components/layout/panel/TextBox';
import Box from 'Components/layout/panel/Box';

export default class AdeptUserOfferDiagnosticReportsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {},
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data, predefinedState } = this.props;
        if (
            data && JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
            predefinedState && JSON.stringify(predefinedState) !== JSON.stringify(prevProps.predefinedState)
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => {
        const { predefinedState } = this.props;

        return {
            ...data,
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        return this.onUpdate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
        });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="adept-user-offer-diagnostic-reports-editor"
                styles={require('./styles')}
            >
                <TextBox 
                    title="Twoja diagnoza"
                    subtitle={data.diagnose}
                    styleVersion={2}
                />
                {data.trainingTarget && (
                    <TextBox 
                        title="Twój wstępny cel treningowy"
                        subtitle={data.trainingTarget}
                        styleVersion={2}
                    />
                )}
                {data.homework && (
                    <TextBox
                        title="Praca domowa"
                        subtitle={data.homework}
                        styleVersion={2}
                    />
                )}
                {data.nextEventDate && (
                    <TextBox
                        title="Data następnego treningu"
                        subtitle={getFormattedDate(data.nextEventDate)}
                        styleVersion={2}
                    />
                )}
                {data.offer && (
                    <TextBox
                        title="Pakiet treningów dobrany dla Ciebie"
                        subtitle={(
                            <Link
                                className="offer-link"
                                to={withVariables(PUBLIC_SHOP_OFFER.path, { slug: data.offer.slug }, {} )}
                            >
                                {data.offer.displayName || data.offer.name}
                            </Link>
                        )}
                        styleVersion={2}
                    />
                )}
                {data.creator && (
                    <TextBox
                        title="Autor raportu"
                        subtitle={getUserFullName(data.creator).label}
                        styleVersion={2}
                    />
                )}
                {data.files && (
                    <Box className="files-container">
                        <h4 className="files-header">Pliki</h4>
                        <div className="files">
                            {data.files.map(file => {
                                let isImage = [ 'image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp' ].includes(file.mimeType);

                                return (
                                    <a
                                        key={file.displayUrl}
                                        href={file.displayUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="file"
                                        style={{ 
                                            backgroundImage: isImage && `url(${file.displayUrl})`,
                                        }}
                                    >
                                        {!isImage && (
                                            <FontAwesomeIcon icon={['fa', 'file']} />
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    </Box>                   
                )}
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2}
                    forms={[{
                        title: 'Twoja ocena',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        submitButtonStyle: 'gradient',
                        layout: 'box',
                        styleVersion: 'transparentLight',
                        elements: [{
                            type: 'input',
                            name: 'eventRating',
                            label: 'Ocena treningu (1-10)',
                            isVisible: Boolean(data),
                            inputProps: {
                                type: 'number',
                                styleVersion: 2,
                            },
                            boxContent: {
                                headline: 'Oceń trening',
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'leadRating',
                            label: 'Ocena treningu (1-10)',
                            isVisible: Boolean(data),
                            inputProps: {
                                type: 'number',
                                styleVersion: 2,
                            },
                            boxContent: {
                                headline: 'Oceń trenera',
                            },
                            required: true,
                        }, {
                            isVisible: Boolean(data && formState.eventRating !== null && !isNaN(formState.eventRating) && formState.eventRating < 7),
                            type: 'textarea',
                            name: 'eventRatingReason',
                            label: 'Ocena treningu - powód',
                            inputProps: {
                                styleVersion: 2,
                            },
                            required: true,
                        }, {
                            isVisible: Boolean(data && formState.leadRating !== null && !isNaN(formState.leadRating) && formState.leadRating < 7),
                            type: 'textarea',
                            name: 'leadRatingReason',
                            label: 'Ocena trenera - powód',
                            inputProps: {
                                styleVersion: 2,
                            },
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}