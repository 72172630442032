import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PUBLIC_SHOP_OFFER } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/adept/userOfferDiagnosticReports/Editor';

export default class AdeptUserOfferDiagnosticReport extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userOfferDiagnosticReport: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userOfferDiagnosticReport, location, history } = this.props;

        return (
            <StyledComponent
                className="adept-user-offer-diagnostic-report-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userOfferDiagnosticReport}
                >     
                    {userOfferDiagnosticReport && userOfferDiagnosticReport.data && (  
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Szczegóły raportu diagnostycznego"
                            controls={[{
                                visible: true,
                                key: 'offerLink',
                                label: 'Przejdź do oferty',
                                style: 'gradient',
                                layout: 'wide',
                                onClick: () => history.push(
                                    withVariables(
                                        PUBLIC_SHOP_OFFER.path,
                                        {
                                            slug: userOfferDiagnosticReport?.data?.offer?.slug,
                                        }
                                    )
                                ),
                            }]}
                            tabs={[{
                                key: 'diagosticReport',
                                label: 'Raport diagnostyczny',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={userOfferDiagnosticReport.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

