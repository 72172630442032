import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`

    .admin-panel-text-box {
        .admin-panel-box {
            .box-wrapper {
                .block {
                    .subtitle {
                        font-size: 1em !important;
                        font-weight: 400 !important;

                        a {
                            .offer-link {
                                color: black;
                                text-decoration: underline;
    
                                &:hover {
                                    color: rgba(0,0,0,.7);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .admin-element-editor {
        .editor-wrapper {
            display: block !important;

            .forms-wrapper {
                width: 100% !important;
                padding-right: 0 !important;

                .form-elements {
                    justify-content: space-evenly;
                    
                    .box {
                        flex-direction: column;
                        width: 48%;
                    }
                    
                    .panel-measurements-box {
                        width: 40%;

                        .admin-panel-box {
                            .measurements-box-content {
                                .measurements-box-body {
                                    .measurements-box-headline {
                                        font-size: 1em;
                                        font-weight: 400;
                                        text-transform: uppercase;
                                    }

                                    .measurements-box-children {
                                        .box-body {
                                            .box-input {
                                                .form-element {
                                                    .input-external-wrapper {
                                                        .input-label {
                                                            font-size: 1em;
                                                        }

                                                        .input-internal-wrapper {
                                                            input {
                                                                font-size: 1.5em;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .files-container {
        .files-header {
            color: ${variables.dpc_fontDark};
            font-size: .9em;
            font-weight: 300;
            text-transform: uppercase;
            line-height: 1.3em;
        }

        .files {
            margin-top: 1em;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            .file {
                width: 10em;
                height: 10em;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 1em;
                margin-bottom: 1em;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 3em;
                    color: #eee;
                }
            }
        }
    }
    
    @media (max-width: ${variables.mobileL}) {
        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    .form-elements {
                        .box {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .files-container {    
            .files {
                flex-direction: column;
    
                .file {
                    width: 100%;
                }
            }
        }
    }
`;
